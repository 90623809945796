<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		withTooltip: boolean;
		noBlurrEffect?: boolean;
		collapsable?: boolean;
	}>(),
	{
		withTooltip: true,
		collapsable: false
	}
);

const showTooltip = ref(false);
const popperRef = ref(null);
const isShowWinnings = ref(false);

onClickOutside(popperRef, () => {
	showTooltip.value = false;
});

const { isOpen } = useAppModals();
const { tourPoints, entries, winnings } = useBalance();
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();

const isGameModal = computed(() => isOpen("LazyOModalGame"));
const isBlurred = computed(() => isGameModal.value && !props.noBlurrEffect);

const toggleWinnings = () => (isShowWinnings.value = !isShowWinnings.value);
const handleSwitchToMode = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});

	await handleSwitchGameMode();

	if (props.withTooltip) {
		showTooltip.value = true;
	}
};
</script>
<template>
	<div :class="['header-balance', { 'is-blurred': isBlurred }]" data-tid="header-balances" @click="handleSwitchToMode">
		<div class="balance-wrapper" :class="{ collapsable }">
			<div class="balance-coins">
				<AText as="div" variant="toledo" :modifiers="['semibold']" class="balance-item text-cannes">
					<AAnimationNumberCounter v-if="isSweepStakes" class="value" :number="entries" :not-animate="isBlurred" />
					<AAnimationNumberCounter v-else class="value" :number="tourPoints" :not-animate="isBlurred" />
				</AText>
				<AText
					v-if="isSweepStakes"
					as="div"
					:modifiers="['semibold', 'uppercase']"
					variant="ternopil"
					class="winnings text-constanta"
				>
					<i18n-t keypath="Winnings: {winnings}">
						<template #winnings>
							<AAnimationNumberCounter :number="winnings" :decimals="2" />
						</template>
					</i18n-t>
				</AText>
			</div>

			<div v-if="collapsable" class="toggle-winnings" @click.stop="toggleWinnings">
				<NuxtIcon name="16/arrow-down-small" class="icon-arrow" :class="{ 'winnings-opened': isShowWinnings }" filled />
			</div>
		</div>

		<div class="balance-toggle" @click="handleSwitchToMode">
			<AToggle :model-value="isSweepStakes" color="var(--canberra)" :size="12" />
		</div>
	</div>
</template>
<style scoped lang="scss">
.header-balance {
	display: flex;
	gap: gutter(1.25);
	height: 40px;
	cursor: pointer;

	&.is-blurred {
		animation: balanceBlur 0.2s linear 5s forwards;

		&:active {
			animation: none;
		}

		@include media-breakpoint-up(lg) {
			&:hover {
				animation: none;
			}
		}
	}

	&.aside-bar-balance {
		@include media-breakpoint-down(lg) {
			@media (orientation: landscape) {
				height: auto;

				.balance-wrapper {
					display: none;
				}

				.balance-toggle {
					&:deep(.inner) {
						width: 38px;
						height: 92px;

						.thumb {
							width: 38px;
							height: 46px;
							transform: translateY(100%);
						}

						&.truthy .thumb {
							left: 0;
							transform: translateY(0%);
						}
					}

					&::before {
						right: 50%;
						transform: translateX(50%);
						top: 12px;
					}

					&::after {
						left: 50%;
						transform: translateX(-50%);
						bottom: 12px;
						top: auto;
					}
				}
			}
		}
	}
}

.balance-wrapper {
	width: 100%;
	min-width: 100px;
	display: flex;
	justify-content: flex-end;
	transition: all 0.2s;

	.balance-coins {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.toggle-winnings {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--cannes);
		border: 1px solid var(--canberra);
		border-radius: 5px;
		width: 18px;

		.icon-arrow {
			transition: all 0.2s;
			rotate: 90deg;

			&.winnings-opened {
				transform: rotateX(180deg);
			}
		}
	}

	&.collapsable {
		max-width: 115px;

		&:has(.winnings-opened) {
			max-width: 195px;
			padding-right: gutter(2.5) !important;
		}
	}
}

.balance-item {
	height: 16px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: gutter(0.25);
	line-height: 14px;

	&:deep(.nuxt-icon) {
		font-size: 18px;
	}

	.value {
		display: inline-block;
	}
}

.balance-coin-img {
	margin: gutter(0.375);
}

.winnings {
	color: var(--costa);
	flex-shrink: 0;
}

.balance-toggle {
	position: relative;

	&::after,
	&::before {
		content: "";
		display: block;
		width: 22px;
		height: 22px;
		position: absolute;
		top: 50%;
		transform: translateY(-53%);
		z-index: 21;
	}

	&::before {
		background: url("~/assets/icons/22/super-coins.svg") center/22px no-repeat;
		right: 13px;
	}

	&::after {
		background: url("~/assets/icons/12/coins.svg") center/22px no-repeat;
		left: 12px;
	}

	&:deep(.inner) {
		width: 92px;
		height: 38px;

		.track {
			border-radius: 999px;
			border: 2px solid var(--chifeng);
			background: var(--carletonville);
			opacity: 1;
		}

		.thumb {
			width: 46px;
			height: 38px;
			top: 0;
			left: 0;
			transform: none;
			transition: 0.2s;
			border-radius: 999px;
			border: 2px solid var(--сirebon);

			&::after {
				background: var(--chilpancingo);
			}
		}

		&.truthy .thumb {
			left: 45px;
			border: 2px solid var(--curico);

			&::after {
				background: var(--coari);
			}
		}
	}

	&:deep(.popper) {
		width: 260px;
		right: -37px !important;

		> #arrow {
			transform: none !important;
			left: auto !important;
			right: 35px !important;
		}
	}

	.popper-content {
		p {
			margin: gutter(2) 0 gutter(0.75);
		}

		span {
			line-height: 20px;
		}
	}
}

@keyframes balanceBlur {
	from {
		filter: blur(0);
	}
	to {
		filter: blur(4px);
	}
}
</style>
